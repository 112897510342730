<script setup>
const localePath = useLocalePath();
const { locale, t } = useI18n();
const gtm = useGtm();

const props = defineProps(['data', 'perex', 'hideType']);

const defaultImage = '/img/dummy/ngp.png';

const track = (eventId) => {
  gtm.trackEvent({
    event: 'eventDetail_select',
    eventId,
    url: window.location.pathname,
  });
};

const getLink = (item) => {
  return localePath({
    name: 'event-id-slug',
    params: { slug: item.slug[locale.value], id: item.id },
  });
};

const getImage = (item) => {
  let image;
  if (item && item.dates && item.dates[0] && item.dates[0].image_list) {
    image = item.dates[0].image_list;
  } else if (item.image && item.image.url != null) {
    image = item.image.url;
  } else {
    image = defaultImage;
  }
  return image;
};
</script>

<template>
  <div class="container-max-content">
    <div class="event-grid">
      <div class="b-flex">
        <div v-for="(item, key) in props.data" :key="key" md="6" xl="4" class="b-flex-child">
          <div class="event-grid__item">
            <nuxt-link :to="getLink(item)" @click="track(item.id)" class="event-grid__image">
              <picture>
                <!-- <source :srcset="useGetImageUrl(getImage(item), 290, 193, 'webp', null, item.imageCrop ? item.imageCrop : null)" type="image/webp" /> -->
                <img :src="useGetImageUrl(getImage(item), 290, 193, 'jpg', null, item.imageCrop ? item.imageCrop : null)" :alt="item.name" class="event-grid__image-element" />
              </picture>
            </nuxt-link>
            <div class="event-grid__main">
              <div class="item-type" v-if="item.categories">{{ item.categories?.[0]?.name }}</div>
              <div class="event-grid__title">
                <div class="heading heading--3">
                  <nuxt-link :to="getLink(item)" class="heading__link">{{ item.name }}</nuxt-link>
                </div>
              </div>

              <ul class="list mt-3" v-if="item.dates.length">
                <li v-if="item.dates.length && item.type !== 'exposition' && useGetCategoryId(item) != 14" class="list__item">
                  {{ useDateRange(item.dates) }}
                </li>

                <template v-if="item.buildings">
                  <li class="list__item" v-for="(item, index) in item.buildings" :key="index">
                    {{ item.name }}
                  </li>
                </template>
              </ul>
              <div v-if="props.perex" class="event-grid__description" v-html="item.description" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.event-grid {
  $s: &;

  &__item {
    #{$s}__image:hover + div {
      .heading__link {
        background-size: 100% 2px;
      }
    }
  }

  &__image {
    position: relative;
    display: block;
    padding-bottom: calc(100% / 3 * 2);
  }

  &__image-element {
    object-fit: cover;
    @include overlay;
  }

  &__type {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__title {
    margin-top: 15px;
  }

  &__title-text {
    @include underline-transition(2px);
  }

  &__meta {
  }

  &__description {
    margin-top: 15px;
    line-height: 1.375;
  }
}

.b-flex {
  @media (min-width: 600px) {
    margin-right: -24px;
    // align-items: baseline;
  }
}

.b-flex-child {
  min-width: 100%;
  @media (min-width: 600px) {
    min-width: calc(50% - 24px);
    max-width: calc(50% - 24px);
    margin-bottom: 1.5rem;
  }

  @media (min-width: 950px) {
    min-width: calc(33.33% - 24px);
    max-width: calc(33.33% - 24px);
    margin-bottom: 1.5rem;
  }
}

.item-type {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: -8px;
  text-transform: uppercase;
  margin-top: 1.5rem;
}
</style>
